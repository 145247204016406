import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout.js";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import BlogTemplateStyles from "./blogTemplate.module.scss";
import Summary from "../components/courseSummary.js";

export const query = graphql `
    query($slug: String!) {
        contentfulCourses(slug: {eq:$slug}) {
            name
            slug
            image {
              file {
                fileName
                url
              }
            }
            type
            price
            description {
              raw
            }
            resume
        }
    }
    `


const CourseTemplate = (props) => {   

    return (
        <Layout>
            <div className={BlogTemplateStyles.blogPost}>
                <div className={BlogTemplateStyles.image} style={{ backgroundImage: `url(${props.data.contentfulCourses.image.file.url})` }}></div>
                <div className={BlogTemplateStyles.postContainer}>
                    <div>
                        <p className={BlogTemplateStyles.category}>{props.data.contentfulCourses.type}</p>
                    </div>
                    <h1 className={BlogTemplateStyles.title}>{props.data.contentfulCourses.name}</h1>
                    <div className={BlogTemplateStyles.socialContainer}>
                        <div class="s9-widget-wrapper"></div>
                    </div>
                    <div className={BlogTemplateStyles.description}>
                        <div className={BlogTemplateStyles.details}>
                            <p>{renderRichText(props.data.contentfulCourses.description)}</p>
                        </div>
                        <div className={BlogTemplateStyles.summary}>
                            <div className={BlogTemplateStyles.boxSummary}>
                                <Summary resume={props.data.contentfulCourses.resume} price={props.data.contentfulCourses.price}/>
                            </div>
                            <div>
                                <button className={BlogTemplateStyles.buy}>Buy Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default CourseTemplate;