import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faClock, faGift, faTag } from '@fortawesome/free-solid-svg-icons';
import SummaryStyles from "./courseSummary.module.scss"

const SummaryRow = ({icon, text}) => {   

    return (
            <li><FontAwesomeIcon icon={icon} /> 
                <p>{text}</p>
            </li>
    )
}

const Summary = ({resume, price}) => {   

    return (
        <div className={SummaryStyles.box}> 
            <ol className={SummaryStyles.ol}>  
                <SummaryRow icon={faClock} text={resume[0]} />
                <SummaryRow icon={faStar} text={resume[1]} />
                <SummaryRow icon={faGift} text={resume[2]} />
            </ol>
            <p className={SummaryStyles.price}>
                <FontAwesomeIcon icon={faTag} /> {price}
            </p>
        </div>
    )
}

export default Summary;